@media only screen and (max-width: 500px) {
    .Sales {
        font-size: 0.75em;
    }

    .Sales .btn {
        padding: .25rem .5rem;
        font-size: .875rem;
        line-height: 1.5;
        border-radius: .2rem;
    }
}

@media only screen and (max-width: 395px) {
    .Sales {
        font-size: 0.5em;
    }
}