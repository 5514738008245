.Analysis {
    margin-bottom: 63px;
}

.Analysis form {
    max-width: 800px;
    margin: 0 auto;
}

.form-group {
    text-align: left;
}

.Analysis button {
    margin-top: 20px;
    float: left;
}

@media only screen and (max-width: 1000px) {
    .Analysis {
        margin: 50px;
    }
}

@media only screen and (max-width: 800px) {
    .Analysis {
        margin-bottom: 56px;
    }
}
@media only screen and (max-width: 768px) {
    .Analysis {
        margin-bottom: 106px;
    }
}