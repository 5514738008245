#externalMarket {
    display: none;
}

@media only screen and (max-width: 800px) {
    nav {
        font-size: 0.75em;
    }

    nav .btn {
        padding: .25rem .5rem;
        font-size: .875rem;
        line-height: 1.5;
        border-radius: .2rem;
    }
}