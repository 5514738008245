.Header {
    width: 100%;
    height: 200px;
    background: url('./../../public/images/banner.jpg') no-repeat top;
    background-size: cover;
    background-position-y: 60%;
}

.headshot {
    height: 190px;
    margin-top: 10px;
    float: left;
}

.logo {
    height: 120px;
    margin: 30px;
    margin-left: 5px;
    float: right;
    background-color:rgba(255, 255, 255, .75);
}

.banner-headlines {
    display: inline-block;
    margin-left: 6%;
}

.Header h1 {
    margin-top: 30px;
    margin-bottom: 5px;
}

.Header h3 {
    margin-top: 0px;
}

.banner-text {
    text-align: right;
    float: right;
    margin-right: 10%;
}

.Header h5 {
    margin-top: 30px;
}

@media only screen and (max-width: 1530px) {
    .banner-text {
        text-align: left;
        float: none;
        margin-right: 0;
        margin-left: 290px;
    }

    .Header h5 {
        font-size: 1em;
        margin-top: 5px;
    }
}

@media only screen and (max-width: 1200px) {
    .Header h1 {
        font-size: 2em;
    }

    .Header h3 {
        font-size: 1.25em;
    }

    .Header h5 {
        font-size: 0.75em;
    }

    .banner-text {
        margin-left: 245px;
    }
}

@media only screen and (max-width: 1045px) {
    .banner-headlines {
        margin-left: 15px;
    }

    .banner-text {
        margin-left: 190px;
    }
}

@media only screen and (max-width: 996px) {
    .Header h1 {
        font-size: 1.6em;
    }

    .Header h3 {
        font-size: 1em;
    }

    .Header h5 {
        font-size: 0.6em;
    }
}

@media only screen and (max-width: 908px) {
    .logo {
        display: none;
    }
}

@media only screen and (max-width: 768px) {
    .Header {
        height: 150px;
    }

    .headshot {
        height: 140px;
    }

    .logo {
        display: block;
        height: 75px;
    }

    .banner-text {
        margin-left: 145px;
    }
    
    .Header h1 {
        font-size: 1.25em;
    }

    .Header h3 {
        font-size: 0.75em;
    }

    .Header h5 {
        font-size: 0.5em;
    }
}

@media only screen and (max-width: 675px) {
    .logo {
        display: block;
        height: 60px;
    }
}

@media only screen and (max-width: 614px) {
    .Header {
        height: 100px;
    }

    .headshot {
        height: 90px;
    }

    .logo {
        display: block;
        height: 40px;
        margin-right: 10px;
    }

    .banner-headlines {
        display: block;
        margin-left: 20%;
    }

    .banner-text {
        display: none;
    }

    .Header h1 {
        font-size: 0.5em;
        font-weight: 700;
        margin-top: 0;
        margin-bottom: 5px;
        padding-top: 20px;
    }

    .Header h3 {
        font-size: 0.5em;
    }
}