.Footer {
    width: 100%;
    text-align: center;
    background-color: #f8f9fa;
}

.Footer p {
    margin: 0;
    padding-top: 9px;
}

.Footer img {
    width: 100px;
}

a {
    text-decoration: none;
    color: black;
}